import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-consultation-view" }
const _hoisted_2 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-consultation__header",
      image: "/static/background/app_background_consultation.jpg",
      icon: ['fad','envelope']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Consultation")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-height": "",
      "fill-width": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_translate, { tag: "p" }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Do you have questions about our software products or would you like a product presentation?")
            ])),
            _: 1
          }),
          _createVNode(_component_translate, { tag: "p" }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Just leave us your phone number and your desired callback date.")
            ])),
            _: 1
          })
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          tooltip: _ctx.user.roles.includes('impersonate_user') ? _ctx.$gettext('Not available as impersonated user') : undefined,
          disabled: _ctx.user.roles.includes('impersonate_user'),
          color: "green",
          icon: ['far', 'envelope'],
          errors: _ctx.errors,
          action: _ctx.sendInquiry
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Send inquiry")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["tooltip", "disabled", "errors", "action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("form", {
          action: "submit",
          class: "flex-column gap-md",
          onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendInquiry && _ctx.sendInquiry(...args)), ["prevent"]))
        }, [
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Email address'),
            onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('email', _ctx.$gettext('Email address'), $event))),
            type: "email",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event))
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Phone number'),
            onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('phone', _ctx.$gettext('Phone number'), $event))),
            type: "tel",
            modelValue: _ctx.phone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event))
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Desired date & time'),
            onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Desired date & time'), $event))),
            required: "",
            "disabled-week-days": [6, 0],
            "min-date": _ctx.minDate,
            type: "datetime",
            modelValue: _ctx.datetime,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.datetime) = $event))
          }, null, 8, ["label", "min-date", "modelValue"]),
          _createVNode(_component_bp_wysiwyg_editor, {
            label: _ctx.$gettext('Message'),
            onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('message', _ctx.$gettext('Message'), $event))),
            "allow-alerts": false,
            maxlength: 1000,
            required: "",
            modelValue: _ctx.message,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.message) = $event))
          }, null, 8, ["label", "modelValue"])
        ], 32)
      ]),
      _: 1
    })
  ]))
}