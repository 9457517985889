
import { $gettext } from 'vue-gettext';
import { computed } from '@vue/reactivity';
import { defineComponent, ref, watch } from 'vue';
import { json } from '@sahnee/ajax';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import { useUser } from '@/utils/user';
import { useUserStore } from '@/stores/user';
import BpToast from '@/components/toast/BpToasts';

export default defineComponent({
  name: 'bp-consultation-view',
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// USER
    ///-------------------------------------------------------------------

    const userStore = useUserStore();
    const user = useUser();

    watch(() => user.value.email, async currentEmail => {
      if (!currentEmail) {
        return;
      }
      const response = await userStore.readByEmail(currentEmail);
      if (response?.success && response.data) {
        phone.value = response.data.phone || '';
        email.value = response.data.email || '';
      }
    }, { immediate: true });

    ///-------------------------------------------------------------------
    /// DATE
    ///-------------------------------------------------------------------

    const minDate = computed(() => {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    })

    ///-------------------------------------------------------------------
    /// FORM
    ///-------------------------------------------------------------------

    const email = ref('');
    const phone = ref('');
    const datetime = ref();
    const message = ref('');

    async function sendInquiry() {
      const response = await json<{ success: boolean; error?: string; }>('/api/consultation', {
        method: 'POST',
        json: {
          userEmail: user.value.email,
          phone: phone.value,
          email: email.value,
          datetime: datetime.value,
          message: message.value,
        }
      });

      if (response.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('Consultation request success'),
          content: $gettext('Requesting consultation was successfull. An employee will contact you shortly.'),
          icon: 'circle-check',
        });
        router.push({ name: 'home' });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Consultation request failed'),
          content: response.error,
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      datetime,
      email,
      errors,
      message,
      minDate,
      phone,
      sendInquiry,
      setError,
      user,
    }
  }
});
